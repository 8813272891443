import axios from "axios";
import Utility from "../../../shared/utility";
import { store } from "../../../state-management/state-mgmt";
export default {
  onPageLoadGetButtons(call, userId, ce) {
    return this.getData(`mm/move_lpn_sort_initial?user_id=${userId}&ce=${ce}`, call);
  },
  wholeLPNTransfer(call, obj, showMessage) {
    return this.getData("/mm/move_lpn_sort_transfer_whole", call, obj, showMessage);
  },
  moveLPNtoIntransit(call, obj, showMessage) {
    return this.getData("/mm/move_lpn_sort_intransit", call, obj, showMessage);
  },
  validationCheckLPNBCN(call, obj, showMessage) {
    return this.getData("/mm/move_lpn_sort_bcn", call, obj, showMessage);
  },
  getLPNContents(call, obj, showMessage) {
    return this.getData("/mm/move_get_lpn_contents", call, obj, showMessage);
  },
  lpnActivityReport(call, obj, showMessage) {
    return this.getData("/mm/lpn_activity_report", call, obj, showMessage);
  },
  projectListIntransitRpt(call, userId) {
    return this.getData(`mm/projectlist_intransit_report?user_id=${userId}`, call);
  },
  lpnTransitRptProj(call, projId) {
    return this.getData(`mm/lpn_intransit_rpt?proj_id=${projId}`, call);
  },
  lpnTransitRptLPN(call, projId, lpn, userId) {
    return this.getData(`mm/lpn_intransit_rpt?proj_id=${projId}&lpn=${lpn}&user_id=${userId}`, call);
  },
  lpnTransitDrill(call, lpn) {
    return this.getData(`mm/lpn_intransit_drill_mt?lpn_id=${lpn}`, call);
  },
  putAwayLPNSortInitial(call, userId, ce) {
    return this.getData(`mm/putaway_lpn_sort_initial?user_id=${userId}&ce=${ce}`, call);
  },
  transferEntireLPN(call, obj, showMessage) {
    return this.getData("/mm/putaway_lpn_val_intransit", call, obj, showMessage);
  },
  putAwayLPNValidateDest(call, obj, showMessage) {
    return this.getData("/mm/putaway_lpn_val_dest", call, obj, showMessage);
  },
  transferSelectUnits(call, obj, showMessage) {
    return this.getData("/mm/putaway_lpn_val_intransit", call, obj, showMessage);
  },
  fieldsValidTransSelUnits(call, obj, showMessage) {
    return this.getData("/mm/putaway_lpn_val_bcn", call, obj, showMessage);
  },
  getConsolidationDetails(call, obj, showMessage) {
    return this.getData("/mm/lpn_consolidate", call, obj, showMessage);
  },
  postOnCompleteTransfer(call, obj, showMessage) {
    return this.getData("/mm/lpn_consolidate_complete", call, obj, showMessage);
  },
  postSubmitForm(call, obj, showMessage) {
    return this.getData("/mm/lpn_consolidate_val_dest_lpn", call, obj, showMessage);
  },
  postLpnConsolidateToLpn(call, obj, showMessage) {
    return this.getData("/mm/lpn_consolidate_lpn_to_lpn", call, obj, showMessage);
  },
  postLpnConsolidateUnitValidate(call, obj, showMessage) {
    return this.getData("/mm/lpn_consolidate_val_unit", call, obj, showMessage);
  },
  postLpnConsolidateSubmitQty(call, obj, showMessage) {
    return this.getData("/mm/lpn_consolidate_units_to_lpn_submit", call, obj, showMessage);
  },
  preShipLPNPickDropoff(call, obj, showMessage) {
    return this.getData("/mm/pre_ship_lpn_pick_dropoff_val_source_lpn", call, obj, showMessage);
  },
  lpnPickDropoffValStageLoc(call, obj, showMessage) {
    return this.getData("/mm/putaway_lpn_pick_dropoff_val_stage_loc", call, obj, showMessage);
  },
  preShipDropoffValStageLPN(call, obj, showMessage) {
    return this.getData("/mm/pre_ship_pick_dropoff_val_stage_lpn", call, obj, showMessage);
  },
  lpnPickDropoffValStageBCN(call, obj, showMessage) {
    return this.getData("/mm/putaway_lpn_pick_dropoff_val_stage_bcn", call, obj, showMessage);
  },
  putAwayLPNTransferUnit(call, obj, showMessage) {
    return this.getData("/mm/putaway_lpn_transfer_unit", call, obj, showMessage);
  },

  async getData(url, call, obj, showMessage) {
    let LoaderDialog = {
      visible: true,
      title: "Please Wait...",
    };
    store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    let res = {};
    if (call == "get") {
      res = await axios.get(url).catch(function (error) {
        if (error.response) {
          let Alert = {
            type: "error",
            isShow: true,
            message: error.response.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        }
      });
    } else {
      res = await axios.post(url, obj).catch(function (error) {
        if (error.response) {
          let Alert = {
            type: "error",
            isShow: true,
            message: error.response.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        }
      });
    }
    if (res !== undefined) {
      if (res.data.body == null) {
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        return JSON.parse('{"message":"NA"}');
      }
      if (res.data.body.message != undefined || res.data.body.message != null) {
        if (!res?.data?.body?.message) {
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(res.data.body.message),
          };
          store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = res.data.body.message;
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          return {};
        }
        try {
          if (showMessage) {
            let Alert = {
              type: "success",
              isShow: true,
              message: res.data.message,
            };
            store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          if (Utility.isJson(res?.data?.body?.message)) return JSON.parse(res?.data?.body?.message);
          else return res?.data?.body?.message;
        } catch (err) {
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(err),
          };
          store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = err.message;
          return {};
        }
      } else if (res.data.body.message == null) {
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        return JSON.parse('{"message":"NA"}');
      } else {
        if (!res?.data?.message) {
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(res.data.message),
          };
          store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = res.data.message;
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          return {};
        }
        try {
          if (showMessage) {
            let Alert = {
              type: "success",
              isShow: true,
              message: res.data.message,
            };
            store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          return JSON.parse(res?.data?.message);
        } catch (err) {
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(err),
          };
          store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = err.message;
          return {};
        }
      }
    }
  },
};
